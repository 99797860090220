import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";
import { Disclosure } from "@headlessui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import MobileMenu from "../../Components/MobileMenu/MobileMenu";
import CookieConsent from "../../Components/Cookie/CookieConsent";
import Background from "../../Assets/bg3.webp";
import Logo from "../../Assets/logo (1).png";
import Player1 from "../../Assets/leftPlayer.webp";
import Player2 from "../../Assets/rightPlayer.webp";
import RewardsCount from "../../Assets/rewards.webp";
import UsersCount from "../../Assets/users.webp";
import WinnersCount from "../../Assets/winners.webp";
import Godown from "../../Assets/goDown.webp";
import Rewards from "../../Assets/EPLRewards1.webp";
import NFTPlayer from "../../Assets/NFTPlayer1.webp";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { getFaqs, eventMixpanel, subscribe } from "../../Api/api";
import Team3 from "../../Assets/Paurav.webp";
import Team4 from "../../Assets/Saurabh.webp";
import Team5 from "../../Assets/Puneet.webp";
import bg1 from "../../Assets/bg1.webp";
import bg2 from "../../Assets/bg2.webp";
import BuildTeam from "../../Assets/Buildteam.webp";
import CommunityImage from "../../Assets/CommunityImage.webp";
import LeaderboboardLive from "../../Assets/leaderboardlive.webp";
import EuroRewards from "../../Assets/EPLRewards2.webp";
import IntegratedMP from "../../Assets/IMarketPlace1.webp";
import { BsArrowLeft } from "react-icons/bs";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsTwitterX } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa6";
import { FaBlogger } from "react-icons/fa";
import AppBar from "../../Components/AppBar";

function Home() {
  const [isMobile, setIsMobile] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [faqs, setFaqs] = useState(null);
  const [faqsData, setFaqsData] = useState(null);
  const questionRef = useRef(null);
  const kPQuestionRef = useRef(null);
  const prizeQuestionRef = useRef(null);
  const feauturesRef = useRef(null);
  const prizesRef = useRef(null);
  const faqsRef = useRef(null);
  useEffect(() => {
    fetchFaq();
    logMixpanelEvent();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (faqsData) {
      if (isReadMore) {
        setFaqs(faqsData);
      } else {
        setFaqs(faqsData?.slice(0, 4));
      }
    }
  }, [isReadMore, faqsData]);

  const handleExpandQuestion = () => {
    setIsReadMore(true);
    setTimeout(() => {
      if (questionRef.current) {
        questionRef.current.click();
        questionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  const handleExpandKPQuestion = () => {
    setIsReadMore(true);
    setTimeout(() => {
      if (!isMobile) {
        if (kPQuestionRef?.current) {
          kPQuestionRef?.current.click();
          kPQuestionRef?.current.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        kPQuestionRef?.current.click();
        let yOffset = -120;
        const y =
          kPQuestionRef?.current?.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 200);
  };

  function handleExpandPrizeQuestion() {
    setIsReadMore(true);
    setTimeout(() => {
      if (!isMobile) {
        if (prizeQuestionRef?.current) {
          prizeQuestionRef?.current.click();
          prizeQuestionRef?.current.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        prizeQuestionRef?.current.click();
        let yOffset = -120;
        const y =
          prizeQuestionRef?.current?.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 200);
  }

  const fetchFaq = async () => {
    try {
      const res = await getFaqs();
      let faq = res?.data?.sort((a, b) => a?.id - b?.id);
      setFaqsData(faq);
    } catch (error) {
      console.log(error.message);
    }
  };

  async function logMixpanelEvent() {
    try {
      await eventMixpanel("Website Landing Page");
    } catch (error) {
      console.log(error);
    }
  }

  async function joinGameNowClickHandler() {
    window.location.href = "https://game.krida.fans/";
  }

  async function navigateTostaking() {
    window.location.href = "https://staking.krida.fans";
  }

  const contents = [
    {
      id: 1,
      photo: Team3,
      title: "Paurav",
      designation: "Co-founder & CEO",
      background: bg1,
      content:
        "10 years of professional experience and has worked in Fortune's top10 company. Won the world hackathon in 2018. In the crypto space since 2016. Fan of Chelsea and plays fantasy football (FPL, Dream11) and cricket (Dream11) for the past 6 years",
    },
    {
      id: 2,
      photo: Team4,
      title: "Saurabh Kumar",
      designation: "Co-founder & CTO",
      background: bg2,
      content:
        "IT veteran and has worked across Fortune's top 100 companies as well as startups for 17+ years. In the crypto space since 2020 and advised crypto projects on the technology side. An ardent fan of Man Utd and plays fantasy football (FPL, UEFA, Dream11) for the last 10 years",
    },
    {
      id: 4,
      photo: Team5,
      title: "Puneet Tiwari",
      designation: "Software Engineer",
      background: bg2,
      content:
        "Passionate developer having more than 2 years of experience in building scalable web apps using javascript and its frameworks such as ReactJS and ExpressJS.",
    },
  ];

  const paddingObject = {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  };
  const blurStyle = {
    filter: "blur(6px)",
  };
  return (
    <>
      <div
        className={`bg-[#010912]  min-h-[100vh] relative ${
          sideBarOpen ? "nav-sidebar-open  overflow-hidden" : "overflow-hidden"
        }`}
      >
        {/* <AppBar /> */}
        <div className=" flex flex-col gap-[4rem] md:gap-[8rem] wrapper">
          {sideBarOpen ? (
            <MobileMenu
              setSideBarOpen={setSideBarOpen}
              feauturesRef={feauturesRef}
              prizesRef={prizesRef}
              faqsRef={faqsRef}
              questionRef={questionRef}
              setIsReadMore={setIsReadMore}
            />
          ) : (
            ""
          )}

          <Header
            setSideBarOpen={setSideBarOpen}
            joinGameNowClickHandler={joinGameNowClickHandler}
            navigateTostaking={navigateTostaking}
            isMobile={isMobile}
            handleExpandQuestion={handleExpandQuestion}
          />
          <div className=" flex flex-col gap-[4rem] md:gap-[8rem]">
            <Work
              joinGameNowClickHandler={joinGameNowClickHandler}
              paddingObject={paddingObject}
              isMobile={isMobile}
            />
            <Reward
              paddingObject={paddingObject}
              prizesRef={prizesRef}
              handleExpandPrizeQuestion={handleExpandPrizeQuestion}
              feauturesRef={feauturesRef}
            />
            <Community
              joinGameNowClickHandler={joinGameNowClickHandler}
              paddingObject={paddingObject}
            />
            <NFTSection
              joinGameNowClickHandler={joinGameNowClickHandler}
              paddingObject={paddingObject}
            />
            <Marketplace
              joinGameNowClickHandler={joinGameNowClickHandler}
              paddingObject={paddingObject}
            />
            <FAQs
              questionRef={questionRef}
              faqs={faqs}
              isReadMore={isReadMore}
              setIsReadMore={setIsReadMore}
              faqsRef={faqsRef}
              kPQuestionRef={kPQuestionRef}
              prizeQuestionRef={prizeQuestionRef}
            />
          </div>
          <AboutUs contents={contents} />
          <Footer subscribe={subscribe} />
        </div>
      </div>
      <CookieConsent />
    </>
  );
}

export default Home;

function Header({
  setSideBarOpen,
  joinGameNowClickHandler,
  navigateTostaking,
  isMobile,
  handleExpandQuestion,
}) {
  function navSideBarOpen() {
    setSideBarOpen(true);
  }
  return (
    <div className="flex flex-col gap-4  mb-[80px] min-h-[90vh] md:min-h-[80vh]">
      <div className="pb-[5px] ">
        <div
          style={{ backgroundImage: `url(${Background})` }}
          className="min-h-[40px] flex flex-col items-center justify-center pb-[100px] md:pb-[0px] "
        >
          <header className="fixed top-0 z-30 md:relative flex justify-between items-center w-[100vw] bg-[black] md:bg-transparent ">
            <Link
              className="title-font font-bold mb-4 md:mb-0 cursor-pointer"
              to="/"
            >
              <img
                className="h-[70px] mt-[10px] ml-[30px] md:ml-[50px] "
                src={Logo}
              />
            </Link>
            <div className="hidden md:flex gap-5 items-center text-white text-[1.2rem] mt-[20px] ml-[25px]">
              <HashLink
                className="py-4  transition duration-200 
                    leading-loose font-regular 
                    capitalize cursor-pointer
                    "
                to="/#features"
                smooth
              >
                Features
              </HashLink>
              <HashLink
                className="py-4  transition duration-200 
                    leading-loose font-regular 
                    capitalize cursor-pointer
                    "
                to="/#prizes"
                smooth
              >
                Prizes
              </HashLink>
              <HashLink
                className="py-4  transition duration-200 
                    leading-loose font-regular 
                    capitalize cursor-pointer
                    "
                to="/#9"
                smooth
                onClick={handleExpandQuestion}
              >
                $KRIDA{" "}
              </HashLink>
              <span className="cursor-pointer" onClick={navigateTostaking}>
                Staking
              </span>
              <HashLink
                className="py-4  transition duration-200 
                    leading-loose font-regular 
                    capitalize cursor-pointer
                    "
                to="/#faq"
                smooth
              >
                FAQ
              </HashLink>
            </div>

            <button
              onClick={joinGameNowClickHandler}
              className="hidden md:block h-fit mt-1 text-base px-4 py-2 bg-yellow-gradient-1 shadow rounded-[10px] text-black font-semibold cursor-pointer mr-[30px] md:mr-[50px]"
            >
              Join the Game!
            </button>

            <button
              onClick={navSideBarOpen}
              className="p-2 sm:p-3
                    cursor-pointer md:hidden  mr-[20px]"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 1024 1024"
                className="h-4 w-4 sm:h-5 sm:w-5 text-white"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
              </svg>
            </button>
          </header>
          <div className="flex items-end pt-[1rem] md:pt-[1rem] mt-[5rem] md:mt-[0rem]">
            <div className="flex flex-col justify-center items-center">
              <p className="custom-white-gradient text-[3rem] text-center md:text-[5rem] font-bold ">
                Free to Play,
                {isMobile ? <br /> : null} Built to Win!
              </p>
            </div>
          </div>
          <div className="mt-4 flex">
            <div
              // style={{ alignSelf: "flex-start" }}
              className=" flex items-end flex-item mb-[-70px] md:mb-[20px] md:mr-[10rem] ml-[1rem] md:ml-[0rem]"
            >
              <img className="h-[100px] md:h-[150px]" src={Player1} />
            </div>{" "}
            <div
              style={{ alignSelf: "flex-end" }}
              className="flex-flex-col h-full"
            >
              <p
                style={{ alignSelf: "flex-start" }}
                className="text-[#B4B4B4] text-[1.4rem] md:text-[2rem] mt-2 md:mt-6 max-w-[300px] md:max-w-[550px] text-center mb-[40px]"
              >
                Experience the Thrill of Fantasy Football and Earn Real Rewards
                on KridaFans.
              </p>
              <div
                style={{ alignSelf: "flex-end" }}
                className="relative flex justify-center"
              >
                <div className="join-line "></div>
                <button
                  onClick={joinGameNowClickHandler}
                  className="join-now font-bold text-[1.4rem] md:text-[1.6rem]"
                >
                  Play Now!
                </button>
              </div>
            </div>
            <div
              // style={{ alignSelf: "flex-start" }}
              className="flex items-end flex-item mb-[-60px]  md:mb-[20px] md:ml-[10rem] mr-[1rem] md:mr-[0rem]"
            >
              <img className="h-[100px] md:h-[150px]" src={Player2} />
            </div>
          </div>

          <div className="hidden md:block fading-bg w-[100vw] h-[60px] md:h-[120px] mt-auto"></div>
        </div>
      </div>
      <div id="go" className="relative">
        <div className="flex flex-col gap-4 absolute top-[-30px] md:top-[-80px] w-[100vw]">
          <div className=" flex justify-center md:gap-8 items-center">
            <div className=" md:w-[160px] px-4 md:px-6  md:mr-10 py-1 flex flex-col items-center bg-black font-inter rounded-[4px]  text-white">
              <span className=" text-[1.5rem] md:text-[2.5rem] text-[#DBDBDB] font-semibold ">
                900+
              </span>
              <span className="text-[#9F9F9F] font-semibold text-[0.7rem] md:text-[1rem] whitespace-nowrap">
                🏆 WINNERS
              </span>
            </div>
            <div className="px-4 md:px-6 py-1 flex flex-col items-center custom-black-gradient rounded-[4px]  text-white">
              <span className=" text-[2rem] md:text-[3.5rem] text-[#60CC3C] font-semibold ">
                $150k+
              </span>
              <span className="text-[#9F9F9F] font-semibold text-[0.7rem] md:text-[1rem] whitespace-nowrap">
                🤑 REWARDS
              </span>
            </div>
            <div className="px-4 md:px-6 md:ml-8 py-1 flex flex-col items-center bg-black  rounded-[4px] text-white">
              <span className="text-[1.5rem] md:text-[2.5rem] text-[#DBDBDB] font-semibold ">
                7000+
              </span>
              <span className="text-[#9F9F9F] font-semibold text-[0.7rem] md:text-[1rem] whitespace-nowrap ">
                🙂 USERS
              </span>
            </div>
          </div>
          <div className="hidden flex justify-center md:gap-8 items-center">
            <img className="w-[100px] md:w-auto" src={WinnersCount} />
            <img className="w-[140px] md:w-auto" src={RewardsCount} />
            <img className="w-[100px] md:w-auto" src={UsersCount} />
          </div>

          <div className="flex justify-center mt-[20px]">
            <HashLink smooth to="/#work">
              <img className="cursor-pointer p-1" src={Godown} />
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}

function Work({ joinGameNowClickHandler, paddingObject, isMobile }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const gliderRef = useRef(null);

  const images = [
    { title: "Build Your Team", src: BuildTeam },
    { title: "Compete", src: LeaderboboardLive },
    { title: "Earn Real Rewards", src: EuroRewards },
  ];

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  //     }, 3000);
  //     return () => clearInterval(interval);
  //   }, []);

  //   useEffect(() => {
  //     gliderRef.current.scrollItem(activeIndex); // Swipe right
  //   }, [activeIndex]);

  const handleTitleClick = (index) => {
    setActiveIndex(index);
    gliderRef.current?.scrollItem(index);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoPlay: true,
    autoPlaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <div
      id="work"
      className="flex flex-col items-center w-full px-[2rem] md:px-[8rem] scroll-margin-top-100 md:scroll-margin-top-80"
    >
      <button className="text-[0.9rem] md:text-[1rem] rounded-[16px] bg-transparent border-[1px] border-white text-white px-6 py-1  mb-[1rem] md:mb-0">
        How does it work?{" "}
      </button>
      <div className="w-full md:flex  md:flex-row gap-6 md:gap-0 items-center md:mt-[4rem] ">
        <div className="hidden md:flex flex-col gap-[6rem] md:ml-[110px]  md:w-[40%] md:pr-[100px]">
          {images.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => handleTitleClick(index)}
                className={`cursor-pointer`}
              >
                <span
                  className={`custom-white-gradient2 text-[1.75rem] font-bold cursor-pointer ${
                    activeIndex === index ? "yellow-bottom-gradient" : ""
                  }`}
                >
                  {" "}
                  {item.title}
                </span>
              </p>
            );
          })}
        </div>
        <div className="hidden md:flex md:w-[60%]">
          <Glider
            ref={gliderRef}
            slidesToShow={1}
            slidesToScroll={1}
            draggable
            rewind={true}
          >
            {images.map((item, index) => (
              <div key={index} className="flex flex-col  items-center gap-4 ">
                <img
                  style={{
                    maxWidth:
                      index == 0
                        ? "500px"
                        : index == 1
                        ? "400px"
                        : index == 2
                        ? "210px"
                        : "",
                    maxHeight: index == 1 ? "360px" : "400px",
                  }}
                  className={`w-[80vw] md:w-auto max-w-[400px]  mb-2 md:mb-0  ${
                    activeIndex === index
                      ? index % 2
                        ? "slide-in-left"
                        : "slide-in-right"
                      : "vanish"
                  } `}
                  key={index}
                  src={item.src}
                  alt={item.title}
                />
                {isMobile ? (
                  <span className="custom-white-gradient2 text-[1.75rem] font-bold cursor-pointer yellow-bottom-gradient">
                    {item.title}
                  </span>
                ) : null}
              </div>
            ))}
          </Glider>
        </div>
        <div className="md:hidden mt-8">
          <Slider {...settings}>
            {images.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="flex flex-col justify-center items-center gap-4 "
              >
                <img
                  style={{
                    maxWidth: "300px",
                    maxHeight: "300px",
                    height: "180px",
                    width: "auto",
                  }}
                  className={`w-[80vw] md:w-auto max-w-[400px]  mb-2 md:mb-0 mx-auto`}
                  key={index}
                  src={item.src}
                  alt={item.title}
                />
                {isMobile ? (
                  <span className="mx-auto w-full text-center custom-white-gradient2 text-[1.75rem] font-bold cursor-pointer yellow-bottom-gradient">
                    {item.title}
                  </span>
                ) : null}
                <div className="w-full h-[20px]"></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <button
        onClick={joinGameNowClickHandler}
        style={paddingObject}
        className="join-now font-bold mt-[4rem]"
      >
        Play Free, Win Big!
      </button>
    </div>
  );
}

function Reward({
  paddingObject,
  prizesRef,
  handleExpandPrizeQuestion,
  feauturesRef,
}) {
  return (
    <div className="flex flex-col items-center gap-2">
      <div
        id="features"
        ref={feauturesRef}
        className="flex flex-col items-center w-full px-[2rem]  md:px-[8rem]"
      >
        <button className="text-[0.9rem] md:text-[1rem] rounded-[16px] bg-transparent border-[1px] border-white text-white px-6 py-1  ">
          Features{" "}
        </button>
      </div>
      <div
        id="prizes"
        ref={prizesRef}
        className="flex flex-col items-center w-full px-[2rem] md:px-[8rem]"
      >
        <div className="w-full flex flex-col md:flex-row gap-6 md:gap-4 items-center  justify-between md:mt-[4rem] ">
          <div className="md:w-[50%] md:flex md:justify-center">
            <img className="max-h-[260px]" src={Rewards} />
          </div>
          <div className="flex flex-col gap-5 md:max-w-[50%] items-center">
            <span className="custom-white-gradient2 text-[1.5rem] md:text-[2rem] font-bold text-center">
              Win Big, Every Game, Every Season!{" "}
            </span>
            <p className="text-[#888888] text-center text-[0.9rem] md:text-[1rem]  ">
              Why play for pennies when you can win big? At KridaFans,
              excitement never dwindles with $1500 prize-pool per round, and a
              total prize pool of $75,000. Your skills could translate into
              substantial earnings.
            </p>
            <button
              onClick={() => {
                // handleExpandPrizeQuestion();
                window.location.href =
                  "https://game.krida.fans/support?section=prizes";
              }}
              style={paddingObject}
              className="join-now font-bold mt-[1.5rem] "
            >
              View Prize Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Community({ joinGameNowClickHandler, paddingObject }) {
  return (
    <div className="flex flex-col items-center w-full px-[2rem] md:px-[8rem]">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-4 items-center justify-between md:mt-[4rem] ">
        <div className="md:hidden">
          <img className="max-h-[200px] mt-[-40px]" src={CommunityImage} />
        </div>
        <div className="flex flex-col gap-5 md:max-w-[50%] items-center">
          <span className="custom-white-gradient2 text-[1.5rem] md:text-[2rem] font-bold text-center">
            More Than a Game - A Community!{" "}
          </span>
          <p className="text-[#888888] text-center text-[0.9rem] md:text-[1rem]  ">
            Join a vibrant community where every voice counts. Dive into our
            exciting round polls and match polls for a chance to win rewards
            ranging from $10 to $30. At KridaFans, every member is valued, and
            every contribution rewarded.
          </p>
          <button
            onClick={() => {
              window.location.href = "https://discord.com/invite/ymrtcuc4Se";
            }}
            style={paddingObject}
            className="join-now font-bold mt-[1.5rem] "
          >
            Join the Community{" "}
          </button>
        </div>
        <div className="hidden md:flex md:w-[50%] md:justify-center">
          <img
            className="h-[250px] mr-[50px] mt-[-40px]"
            src={CommunityImage}
          />
        </div>
      </div>
    </div>
  );
}

function NFTSection({ joinGameNowClickHandler, paddingObject }) {
  return (
    <div className="flex flex-col items-center w-full px-[2rem] md:px-[8rem]">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-4 items-center justify-between md:mt-[4rem] ">
        <div className="md:w-[50%] md:flex md:justify-center ">
          <img className="max-h-[250px]" src={NFTPlayer} />
        </div>
        <div className="flex flex-col gap-5 md:max-w-[50%] items-center">
          <span className="custom-white-gradient2 text-[1.5rem] md:text-[2rem] font-bold whitespace-normal  w1200:whitespace-nowrap lg:whitespace-nowrap text-center">
            Craft Your Legacy, Outsmart the Competition!
          </span>
          <p className="text-[#888888] text-center text-[0.9rem] md:text-[1rem] ">
            Choose your Captain and Vice-Captain wisely, as your PlayerCards’
            credits pivot on the performance of your C/VC against the top scorer
            players of the round. Experience the thrill of strategy and
            anticipation, as every decision could lead to glory.
          </p>
          <button
            onClick={joinGameNowClickHandler}
            className="join-now font-bold mt-[1.5rem] "
            style={paddingObject}
          >
            Build Your Team
          </button>
        </div>
      </div>
    </div>
  );
}

function Marketplace({ joinGameNowClickHandler, paddingObject }) {
  return (
    <div className="flex flex-col items-center w-full px-[2rem] md:px-[8rem]">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-4 items-center justify-between md:mt-[4rem] ">
        <div className="md:hidden">
          <img className="max-h-[200px] mt-[-40px]" src={IntegratedMP} />
        </div>
        <div className="flex flex-col gap-5 md:max-w-[50%] items-center">
          <span className="custom-white-gradient2 text-[1.5rem] md:text-[2rem] font-bold text-center">
            Your Strategy, Perfected with Integrated Marketplace
          </span>
          <p className="text-[#888888] text-center text-[0.9rem] md:text-[1rem]  ">
            KridaFans offers a unique integrated player card marketplace,
            allowing you to choose rare cards while building your team without
            interruption. Want more options? Explore our extensive marketplace
            to refine your strategy with all available player cards.
          </p>
          <button
            onClick={joinGameNowClickHandler}
            style={paddingObject}
            className="join-now font-bold mt-[1.5rem] "
          >
            Perfect Your Strategy
          </button>
        </div>
        <div className="hidden md:flex md:w-[50%] md:justify-center">
          <img className="h-[320px] mr-[50px] mt-[-40px]" src={IntegratedMP} />
        </div>
      </div>
    </div>
  );
}
function FAQs({
  faqs,
  questionRef,
  isReadMore,
  setIsReadMore,
  faqsRef,
  kPQuestionRef,
  prizeQuestionRef,
}) {
  function readMoreHandler() {
    setIsReadMore(true);
  }
  return (
    <div
      id="faq"
      ref={faqsRef}
      className="flex flex-col items-center px-[2rem] md:px-[8rem]  "
    >
      <button className="text-[0.9rem] md:text-[1rem] rounded-[16px] w-fit bg-transparent border-[1px] border-white text-white px-6 py-1  ">
        Frequently Asked Questions{" "}
      </button>
      <div className="mt-6 w-[98%] md:w-[60%]">
        {faqs &&
          faqs.length &&
          faqs.map((f) => (
            <Disclosure
              as="div"
              className="mt-2 faqs  border-[1px] border-[#434343] rounded-[8px] p-[0.6rem]
            "
              id={f.id}
              key={f.id}
            >
              {({ open }) => (
                <>
                  <Disclosure.Button
                    ref={
                      f.id === 9
                        ? questionRef
                        : f.id === 6
                        ? kPQuestionRef
                        : f.id === 18
                        ? prizeQuestionRef
                        : null
                    }
                    className="flex justify-between items-center w-full text-left  focus:outline-none"
                  >
                    <span className="md:text-[1.25rem]  leading-relaxed text-[#FFFFFF]">
                      {f.question}
                    </span>{" "}
                    <span>
                      {open ? (
                        <span className="text-3xl w-6 h-6">
                          <AiOutlineMinusCircle color="white" />
                        </span>
                      ) : (
                        <span className="text-3xl w-6 h-6">
                          <AiOutlinePlusCircle color="white" />
                        </span>
                      )}
                    </span>
                  </Disclosure.Button>
                  <Disclosure.Panel className="mt-2 pb-2 text-left text-[0.9rem] break-words md:text-[1.1rem] text-[#888888]">
                    <ReactMarkdown
                      children={f.answer}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        // Override the default behavior of ReactMarkdown for rendering links
                        a: ({ node, ...props }) => (
                          <a
                            {...props}
                            className="text-[#FE7A36] underline "
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
      </div>
      {!isReadMore ? (
        <div className="mt-2">
          <button
            className="text-[#888888] underline text-[1.1rem]"
            onClick={readMoreHandler}
          >
            Read more{" "}
          </button>
        </div>
      ) : null}
    </div>
  );
}

function AboutUs({ contents, paddingObject }) {
  const [showStory, setShowStory] = useState(false);

  const readMoreClickHandler = () => {
    setShowStory((prev) => !prev);
  };
  return (
    <div
      id="aboutus"
      className="flex justify-center  w-full px-[1rem] md:px-[8rem] "
    >
      <div className="flex flex-col items-center w-[95%] md:w-[90%]">
        <button className="text-[0.9rem] md:text-[1rem] rounded-[16px]  bg-transparent border-[1px] border-white text-white px-12 py-1  ">
          About Us{" "}
        </button>
        <p className="  custom-white-gradient2 text-[1.5rem] md:text-[2rem] font-bold text-center mt-4 mb-4">
          Meet the Visionaries Behind KridaFans
        </p>
        <p className=" max-w-[80vw] md:max-w-[50vw] text-[#B4B4B4] text-[0.9rem] md:text-[1.25rem] text-center">
          KridaFans is more than a platform, it's a dream brought to life by a
          team dedicated to revolutionizing fantasy football. Discover our
          journey, vision, and the relentless pursuit of innovation that fuels
          our community.
        </p>
        <div
          className={`flex justify-center gap-2 md:gap-4 mt-6 mb-2 ${
            showStory ? "flex-col items-center" : ""
          }`}
        >
          <div
            className={`flex gap-6 ${
              showStory ? "w-[80%] md:w-auto items-center" : ""
            }`}
          >
            <div className="  bg-[#1E1E1E] flex flex-col text-white justify-center items-center p-2 md:p-6 rounded-[8px]">
              <img
                className={` ${
                  showStory ? "h-[80px] md:h-[155px] " : ""
                } w-[90%] md:h-[140px]`}
                src={contents[0]?.photo}
              />
              <div className="flex flex-col items-center mt-3">
                <span className="text-[1rem] md:text-[1.1rem] font-semibold text-center">
                  {contents[0]?.title}
                </span>
                <span className="text-[0.8rem] md:text-[0.9rem] text-center">
                  {contents[0]?.designation}
                </span>
              </div>
            </div>
            {showStory ? (
              <div className="bg-[#1E1E1E]  h-auto md:h-[100%] w-[300px] md:w-[400px] flex flex-col text-white justify-center items-center p-2 md:p-4 rounded-[8px]">
                {contents[0]?.content}
              </div>
            ) : null}
          </div>
          <div
            className={`flex gap-6 ${
              showStory ? "w-[80%] md:w-auto items-center" : ""
            }`}
          >
            <div className=" bg-[#1E1E1E] flex flex-col  text-white justify-center items-center p-2 md:p-6 md:px-3  rounded-[8px]">
              <img
                className={`${
                  showStory ? "min-w-[0] w-[80px] md:w-auto" : ""
                }  min-w-[100px] md:min-w-[0px] w-[80%] md:h-[140px]`}
                src={contents[1]?.photo}
              />
              <div className="flex flex-col items-center mt-3">
                <span className="hidden md:inline-block text-[1rem] md:text-[1.1rem] text-center font-semibold">
                  {contents[1]?.title}
                </span>
                <span className=" md:hidden text-[1rem] md:text-[1.1rem] font-semibold text-center">
                  {contents[1]?.title.split(" ")[0]}
                </span>
                <span className="text-[0.8rem] md:text-[0.9rem] text-center">
                  {contents[1]?.designation}
                </span>
              </div>
            </div>
            {showStory ? (
              <div className="bg-[#1E1E1E] h-auto md:h-[100%] w-[300px] md:w-[400px] flex flex-col text-white justify-center items-center p-2 md:p-4 rounded-[8px]">
                {contents[1]?.content}
              </div>
            ) : null}
          </div>

          <div
            className={`flex gap-6 ${
              showStory ? "w-[80%] md:w-auto items-center " : ""
            }`}
          >
            <div className=" bg-[#1E1E1E] flex flex-col text-white justify-center items-center p-2 md:p-6 rounded-[8px]">
              <img
                className={`${
                  showStory ? "w-[80px] md:w-auto" : ""
                }   md:h-[140px]`}
                src={contents[2]?.photo}
              />
              <div className="flex flex-col items-center mt-3">
                <span className="hidden md:inline-block text-center text-[1rem] md:text-[1.1rem] font-semibold">
                  {contents[2]?.title}
                </span>
                <span className=" md:hidden text-center text-[1rem] md:text-[1.1rem] font-semibold">
                  {contents[2]?.title.split(" ")[0]}
                </span>
                <span className="text-[0.8rem] md:text-[0.9rem] text-center">
                  {contents[2]?.designation}
                </span>
              </div>
            </div>
            {showStory ? (
              <div className="bg-[#1E1E1E] h-auto md:h-[100%] w-[300px] md:w-[400px] flex flex-col text-white justify-center items-center p-2 md:p-4 rounded-[8px]">
                {contents[2]?.content}
              </div>
            ) : null}
          </div>
        </div>

        <button
          onClick={readMoreClickHandler}
          style={paddingObject}
          className="hidden join-now font-bold mt-[1.5rem] flex items-center "
        >
          {showStory ? <BsArrowLeft className="mr-0" /> : null}{" "}
          {showStory ? "" : "Read Our Story"}
        </button>
      </div>
    </div>
  );
}

const Footer = ({ subscribe }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleClick = async () => {
    try {
      let data = { email: email };
      let res = await subscribe(data);
      setMessage(res);
      setEmail("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className="flex flex-col border-t-2 border-[#3A3A3A]
    "
    >
      <div
        className="overflow-hidden text-white text-[1rem] py-6 w-full"
        id="footer-background"
      >
        <div className="px-4 md:px-24 h-full">
          <div className="grid grid-cols-5 text-white">
            <div className="flex flex-col text-left items-start md:mt-[-15px] space-y-2 col-span-5 md:col-span-1">
              <Link
                className="title-font font-bold mb-4 md:mb-0 md:mt2 cursor-pointer"
                to="/"
              >
                <img src={Logo} alt="" className="w-12 h-12" />
              </Link>

              <div>
                <span>Connect with us on social media</span>
                <div className={`flex flex-row items-center space-x-4 mt-2`}>
                  <a href="https://twitter.com/kridafans" target="_blank">
                    <BsTwitterX className="mb-4 w-6 h-6" />
                  </a>
                  <a
                    href="https://discord.com/invite/ymrtcuc4Se"
                    target="_blank"
                  >
                    <FaDiscord className="mb-4 w-8 h-8" />
                  </a>
                  <a href="https://blog.krida.fans" target="_blank">
                    <FaBlogger className="mb-4 w-8 h-8" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-left items-start col-span-5 md:col-span-1 space-y-4 mb-8 md:mb-0">
              <a href="https://blog.krida.fans">
                <span>Blog</span>
              </a>{" "}
              <a href="https://docsend.com/view/ngmh2ani6yq8kksg">
                <span>White Paper</span>
              </a>{" "}
            </div>
            <div className="flex flex-col text-left items-start space-y-4 col-span-5 md:col-span-1 mb-8 md:mb-0">
              <HashLink to="/#features" smooth>
                Features
              </HashLink>
              <a href="https://staking.krida.fans">
                <span>Staking</span>
              </a>{" "}
              <HashLink to="/#faq" smooth>
                FAQs
              </HashLink>
            </div>
            <div className="flex flex-col text-left items-start space-y-4 col-span-5 md:col-span-1 mb-8 md:mb-0">
              <Link to="/privacy">
                <span>Privacy Policy</span>
              </Link>
              <Link to="/terms-and-conditions">
                <span>Terms of use</span>
              </Link>
            </div>
            <div className="flex flex-col text-center items-start xl:text-left xl:items-start col-span-5 md:col-span-1 mb-8 md:mb-0">
              <span className="text-sm mb-2">Subscribe to our newsletter</span>
              <div className="flex flex-row text-center items-center search-wrapper cf">
                <input
                  className="p-[0.4rem] text-black"
                  type="text"
                  placeholder="Enter your email..."
                  style={{ boxShadow: "none" }}
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="ml-1 p-[0.5rem] bg-[#333333] "
                  type="submit"
                  onClick={handleClick}
                >
                  Subscribe
                </button>
              </div>
              <span className="text-[#16C60C] text-sm">
                {message !== "" ? message : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="bg-[#3A3A3A]" /> */}
      <div className="pt-2 pb-4 text-[#fff]">
        <div className="w-full">
          <p className="text-sm text-center mb-4 xl:mb-0">
            © 2024 Krida Fans. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};
