import React, { useEffect } from "react";
import fileUrl from "../../Assets/KridaFansTermsofService.pdf";
export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="w-full h-full"
      //   className="App min-h-screen text-white pt-10 pb-10 relative flex items-center"
      style={{ background: "#000" }}
    >
      <div className="w-full px-4 md:px-24 mx-auto h-full">
        <iframe
          src={fileUrl}
          width="100%"
          height="600"
          style={{ overflow: "hidden" }}
          title="Terms and conditions"
        ></iframe>
      </div>
    </div>
  );
}
