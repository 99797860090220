import React, { useEffect } from "react";
import fileUrl from "../../Assets/KridaFansPrivacyPolicy.pdf";
export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="App min-h-screen text-white pb-10 relative flex items-center"
      style={{ background: "#000" }}
    >
      <div
        className="w-full h-full"
        //   className="w-full px-4 md:px-24 mx-auto h-full mt-20"
        id="privacy"
      >
        <iframe
          title="Privacy and Policy"
          src={fileUrl}
          width="100%"
          height="600"
          style={{ overflow: "hidden" }}
        ></iframe>
      </div>
    </div>
  );
}
