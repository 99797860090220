import { CMS_API, DEV_API } from "../config";
import axios from "axios";

const getFaqs = async () => {
  let path = `${CMS_API}/faqs`;
  try {
    const res = await axios.get(path);
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
const subscribe = async (data) => {
  try {
    const res = await axios.post(`${DEV_API}/user-data/subscribe`, data);
    return res.data.message;
  } catch (error) {
    console.error(error.message);
  }
};
export async function eventMixpanel(name, props = {}) {
  const restapi = axios.create({
    baseURL: DEV_API || "https://dev-api.krida.fans",
  });

  restapi.interceptors.request.use(
    (config) => {
      config.headers["krida_referrer"] = document?.referrer;
      config.headers["krida_cookie"] = document?.cookie;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  let randomUserId = generateRandomUserID();

  try {
    await restapi.put(`/user-data/mixpanel-identify/${randomUserId}`, {});
  } catch (error) {
    console.error(error.message);
  }

  try {
    await restapi.post(`/user-data/mixpanel-event/validated/${randomUserId}`, {
      event_name: name,
      properties: props,
    });
  } catch (error) {
    console.error(error.message);
  }
}

export function generateRandomUserID() {
  const randomString = Math.random().toString(36).substr(2, 8); // Adjust the length of the random string as needed
  const timestamp = Date.now();
  const userID = `${randomString}_${timestamp}`;
  return userID;
}

async function sendAppSms(walletKey, phone) {
  return await axios.post(`${DEV_API}/user-data/send-sms`, {
    walletKey,
    phone,
  });
}

export { subscribe, getFaqs, sendAppSms };
