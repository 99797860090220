import TeamsImage from "../../Assets/downloadTeams.webp";
import PlayersImage from "../../Assets/downloadPlayers.webp";
import "./download.scss";
import { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { eventMixpanel, generateRandomUserID, sendAppSms } from "../../Api/api";
import { HashLink } from "react-router-hash-link";
import Background from "../../Assets/bg3.webp";
import Logo from "../../Assets/logo (1).png";
import { Link } from "react-router-dom";
import { BsTwitterX } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa6";
import { FaBlogger } from "react-icons/fa";
import MobileMenu from "../../Components/MobileMenu/MobileMenu";

export default function AppDownloadPage() {
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const questionRef = useRef(null);
  const feauturesRef = useRef(null);
  const prizesRef = useRef(null);
  const faqsRef = useRef(null);

  const sendSMS = async () => {
    if (isSent || !phone) {
      return;
    }

    eventMixpanel("App downlaod Button Clicked");
    setIsLoading(true);
    const response = await sendAppSms(generateRandomUserID(), "+" + phone);
    if (response.data) {
      setIsSent(true);
      setIsLoading(false);
    }
  };

  async function joinGameNowClickHandler() {
    window.location.href = "https://game.krida.fans/";
  }

  async function navigateTostaking() {
    window.location.href = "https://staking.krida.fans";
  }

  const handleDownload = () => {
    eventMixpanel("App downlaod Button Clicked");
    const link = document.createElement("a");
    link.href = "https://bit.ly/4b3e97L";
    link.download = "KridaFans";
    // Append the anchor element to the body
    document.body.appendChild(link);
    // Trigger a click on the anchor element
    link.click();
    // Clean up
    document.body.removeChild(link);
  };

  function navSideBarOpen() {
    console.log("check");
    setSideBarOpen(true);
  }

  return (
    <div
      className={`bg-[#010912]  min-h-[100vh] relative ${
        sideBarOpen ? "nav-sidebar-open  overflow-hidden" : "overflow-hidden"
      }`}
    >
      {sideBarOpen ? (
        <MobileMenu
          setSideBarOpen={setSideBarOpen}
          feauturesRef={feauturesRef}
          prizesRef={prizesRef}
          faqsRef={faqsRef}
          questionRef={questionRef}
        />
      ) : (
        ""
      )}

      <div className="flex flex-col gap-4 mb-[80px]">
        <div className="pb-[5px] ">
          <div
            style={{
              backgroundImage: `url(${Background})`,
              backgroundRepeat: "no-repeat",
            }}
            className="min-h-[40px] flex flex-col items-center justify-center pb-[100px] md:pb-[0px] "
          >
            <header className="fixed top-0 z-30 md:relative flex justify-between items-center w-[100vw] bg-[black] md:bg-transparent ">
              <Link
                className="title-font font-bold mb-4 md:mb-0 cursor-pointer"
                to="/"
              >
                <img
                  className="h-[70px] mt-[10px] ml-[30px] md:ml-[50px] "
                  src={Logo}
                />
              </Link>
              <div className="hidden md:flex gap-5 items-center text-white text-[1.2rem] mt-[20px] ml-[25px]">
                <HashLink
                  className="py-4  transition duration-200 
                      leading-loose font-regular 
                      capitalize cursor-pointer
                      "
                  to="/#features"
                  smooth
                >
                  Features
                </HashLink>
                <HashLink
                  className="py-4  transition duration-200 
                      leading-loose font-regular 
                      capitalize cursor-pointer
                      "
                  to="/#prizes"
                  smooth
                >
                  Prizes
                </HashLink>
                <HashLink
                  className="py-4  transition duration-200 
                      leading-loose font-regular 
                      capitalize cursor-pointer
                      "
                  to="/#9"
                  smooth
                  // onClick={handleExpandQuestion}
                >
                  $KRIDA{" "}
                </HashLink>
                <span className="cursor-pointer" onClick={navigateTostaking}>
                  Staking
                </span>
                <HashLink
                  className="py-4  transition duration-200 
                      leading-loose font-regular 
                      capitalize cursor-pointer
                      "
                  to="/#faq"
                  smooth
                >
                  FAQ
                </HashLink>
              </div>

              <button
                onClick={joinGameNowClickHandler}
                className="hidden md:block h-fit mt-1 text-base px-4 py-2 bg-yellow-gradient-1 shadow rounded-[10px] text-black font-semibold cursor-pointer mr-[30px] md:mr-[50px]"
              >
                Engage to Earn
              </button>

              <button
                onClick={navSideBarOpen}
                className="p-2 sm:p-3
                      cursor-pointer md:hidden  mr-[20px]"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 1024 1024"
                  className="h-4 w-4 sm:h-5 sm:w-5 text-white"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
                </svg>
              </button>
            </header>
            <div className="mx-auto mt-[5.8rem] md:mt-0 md:mb-[150px] flex flex-col gap-5 md:gap-8 md:flex-row px-3 md:px-0">
              <div className="px-2 sm:px-6 lg:px-8 w-full">
                <div className="flex flex-col md:flex-row items-center justify-between font-[mulish] gap-4 md:gap-[none]">
                  <div
                    className="w-full md:w-1/2 h-[40vh] sm:h-[60vh] lg:h-[80vh] flex justify-center items-end"
                    style={{
                      backgroundImage: `url(${TeamsImage})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                    }}
                  >
                    <img
                      className="w-[280px] sm:w-[380px] lg:w-[550px]"
                      src={PlayersImage}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <p className="italic text-[40px] leading-none md:text-7xl font-extrabold text-center engage-text">
                      Engage, Play,
                      <br /> and Earn!
                    </p>
                    <p className="text-center text-base md:text-xl text-[#D9D9D9] mt-9 mb-10">
                      Download the official KridaFans App
                    </p>
                    <div className="flex justify-center items-center">
                      <button
                        onClick={handleDownload}
                        className="block md:hidden h-fit mt-1 text-base px-4 py-2 bg-yellow-gradient-1 shadow rounded-[10px] text-black font-semibold cursor-pointer"
                      >
                        Download App
                      </button>
                    </div>

                    <div className="hidden md:flex flex-col md:flex-row justify-center items-baseline gap-2">
                      <div className="relative">
                        {isSent ? null : (
                          <span className="text-sm absolute left-[0px] top-[-19px] text-[#D9D9D9]">
                            Enter Mobile Number
                          </span>
                        )}
                        {isSent ? null : (
                          <PhoneInput
                            containerClass="!bg-[transparent]"
                            dropdownClass="text-black !w-[88vw] sm:!w-[200px]"
                            inputClass="text-white !bg-[transparent] !w-[100%] sm:!w-[200px]"
                            country={"in"}
                            // enableSearch={true}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                          />
                        )}
                      </div>
                      <button
                        disabled={isSent}
                        onClick={sendSMS}
                        className="hidden md:block h-fit mt-1 text-base px-4 py-2 bg-yellow-gradient-1 shadow rounded-[10px] text-black font-semibold cursor-pointer mr-[30px] md:mr-[50px] min-w-32"
                        style={{ filter: isSent ? "grayscale(1)" : "none" }}
                      >
                        {isLoading ? (
                          <span>Sending...</span>
                        ) : isSent ? (
                          "Sent"
                        ) : (
                          "GET APP LINK"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const Footer = ({ subscribe }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleClick = async () => {
    try {
      let data = { email: email };
      let res = await subscribe(data);
      setMessage(res);
      setEmail("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className="flex flex-col border-t-2 border-[#3A3A3A]
      "
    >
      <div
        className="overflow-hidden text-white text-[1rem] py-6 w-full"
        id="footer-background"
      >
        <div className="px-4 md:px-24 h-full">
          <div className="grid grid-cols-5 text-white">
            <div className="flex flex-col text-left items-start md:mt-[-15px] space-y-2 col-span-5 md:col-span-1">
              <Link
                className="title-font font-bold mb-4 md:mb-0 md:mt2 cursor-pointer"
                to="/"
              >
                <img src={Logo} alt="" className="w-12 h-12" />
              </Link>

              <div>
                <span>Connect with us on social media</span>
                <div className={`flex flex-row items-center space-x-4 mt-2`}>
                  <a href="https://twitter.com/kridafans" target="_blank">
                    <BsTwitterX className="mb-4 w-6 h-6" />
                  </a>
                  <a
                    href="https://discord.com/invite/ymrtcuc4Se"
                    target="_blank"
                  >
                    <FaDiscord className="mb-4 w-8 h-8" />
                  </a>
                  <a href="https://blog.krida.fans" target="_blank">
                    <FaBlogger className="mb-4 w-8 h-8" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-left items-start col-span-5 md:col-span-1 space-y-4 mb-8 md:mb-0">
              <a href="https://blog.krida.fans">
                <span>Blog</span>
              </a>{" "}
              <a href="https://docsend.com/view/ngmh2ani6yq8kksg">
                <span>White Paper</span>
              </a>{" "}
            </div>
            <div className="flex flex-col text-left items-start space-y-4 col-span-5 md:col-span-1 mb-8 md:mb-0">
              <HashLink to="/#features" smooth>
                Features
              </HashLink>
              <a href="https://staking.krida.fans">
                <span>Staking</span>
              </a>{" "}
              <HashLink to="/#faq" smooth>
                FAQs
              </HashLink>
            </div>
            <div className="flex flex-col text-left items-start space-y-4 col-span-5 md:col-span-1 mb-8 md:mb-0">
              <Link to="/privacy">
                <span>Privacy Policy</span>
              </Link>
              <Link to="/terms-and-conditions">
                <span>Terms of use</span>
              </Link>
            </div>
            <div className="flex flex-col text-center items-start xl:text-left xl:items-start col-span-5 md:col-span-1 mb-8 md:mb-0">
              <span className="text-sm mb-2">Subscribe to our newsletter</span>
              <div className="flex flex-row text-center items-center search-wrapper cf">
                <input
                  className="p-[0.4rem] text-black"
                  type="text"
                  placeholder="Enter your email..."
                  style={{ boxShadow: "none" }}
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="ml-1 p-[0.5rem] bg-[#333333] "
                  type="submit"
                  onClick={handleClick}
                >
                  Subscribe
                </button>
              </div>
              <span className="text-[#16C60C] text-sm">
                {message !== "" ? message : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="bg-[#3A3A3A]" /> */}
      <div className="pt-2 pb-4 text-[#fff]">
        <div className="w-full">
          <p className="text-sm text-center mb-4 xl:mb-0">
            © 2024 Krida Fans. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};
