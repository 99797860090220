import React from "react";
import { HashLink } from "react-router-hash-link";
const MobileMenu = ({
  setSideBarOpen,
  feauturesRef,
  prizesRef,
  faqsRef,
  questionRef,
  setIsReadMore,
}) => {
  const yOffset = -120;
  const navSideBarClose = () => {
    setSideBarOpen(false);
  };
  const handleClick = () => {
    navSideBarClose();
    window.location.href = "https://staking.krida.fans";
  };

  function handleFeatures() {
    navSideBarClose();
    setTimeout(() => {
      const y =
        feauturesRef?.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  }

  function handlePrizes() {
    navSideBarClose();
    setTimeout(() => {
      const y =
        prizesRef?.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  }

  function handleFAQs() {
    navSideBarClose();
    setTimeout(() => {
      const y =
        faqsRef?.current?.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  }

  function handleKrida() {
    navSideBarClose();
    setIsReadMore(true);
    setTimeout(() => {
      if (questionRef.current) {
        questionRef.current.click();
        const y =
          questionRef?.current?.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 500);
  }
  return (
    <div>
      <nav className="absolute top-0 left-0 w-80 transform -translate-x-80 overflow-y-auto h-screen z-50 transition ease-in-out duration-short bg-white font-AvenirLTStd">
        <div className="flex flex-col sm:flex-row sm:justify-around">
          <div className="w-full">
            <div className="flex items-center justify-between mt-10 px-8">
              <p className="text-base font-bold uppercase"></p>
              <div role="button" onClick={navSideBarClose}>
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  className="h-7 w-7 cursor-pointer"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <nav className="mt-10">
              <HashLink
                onClick={navSideBarClose}
                className="text-xl flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                to="/#home"
                smooth
              >
                <span className="text-base font-normal capitalize">Home</span>
              </HashLink>
              <HashLink
                onClick={handleFeatures}
                className="text-base flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                // to="/#features"
                smooth
              >
                <span className="text-base font-normal capitalize">
                  Features
                </span>
              </HashLink>
              <HashLink
                onClick={handlePrizes}
                className="text-base flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                // to="/#prizes"
                smooth
              >
                <span className="text-base font-normal capitalize">Prizes</span>
              </HashLink>

              <HashLink
                onClick={handleKrida}
                className="text-base flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                // to="/#9"
                smooth
              >
                <span className="text-base font-normal capitalize">$KRIDA</span>
              </HashLink>

              <span
                className="text-base flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                onClick={handleClick}
              >
                <span className="text-base font-normal capitalize">
                  Staking
                </span>
              </span>
              <HashLink
                onClick={handleFAQs}
                className="text-base flex items-center mt-2 py-4 px-8 border-r-4 border-white hover:bg-gray hover:border-orange transform duration-short ease-in"
                role="button"
                // to="/#faq"
                smooth
              >
                <span className="text-base font-normal capitalize">FAQs</span>
              </HashLink>
            </nav>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default MobileMenu;
