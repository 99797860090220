import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import ConsentImage from "../../Assets/consent.webp";
import { Close } from "@mui/icons-material";
import Cookies from "js-cookie";

function CookieConsent() {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    checkConsent();
  }, []);

  const checkConsent = () => {
    const hasConsent = Cookies.get("cookieConsent");
    if (hasConsent) {
      setConsentGiven(true);
    }
  };

  const handleAccept = () => {
    Cookies.set("cookieConsent", true, { expires: 365 });
    setConsentGiven(true);
  };

  if (consentGiven) {
    return null; // Render nothing if consent already given
  }

  return createPortal(
    <>
      {" "}
      <div className="fixed inset-0 bg-black bg-opacity-[0.6] z-[99999999999]"></div>
      <div className="bg-[#1C1C1C] rounded-[20px] sm:px-5 px-2 py-3 sm:w-[500px] w-[96%] flex items-center pt-5 fixed bottom-4 sm:left-4 sm:right-auto right-0 left-0 z-[99999999999] mx-auto">
        <Close
          className="text-[#515151] text-3xl right-[20px] top-[10px] absolute cursor-pointer"
          onClick={handleAccept}
        />

        <div className="w-[50%]">
          <img
            className="sm:w-[95.75px] w-[72.12px] sm:h-[91.61px] h-[69px]"
            src={ConsentImage}
          />
        </div>
        <div className="sm:px-4 px-2">
          {" "}
          <p className="text-white text-sm sm:text-2xl font-bold">
            Our website uses cookies
          </p>
          <p className="text-[#D9D9D9] sm:text-base text-[11px]">
            Our website use cookies. By continuing, we assume your permission to
            deploy cookies as detailed in our{" "}
            <a
              href="https://krida.fans/privacy"
              target="_blank"
              className="text-[#FDBC19]"
              rel="noreferrer"
            >
              Privacy Policy.
            </a>
          </p>
        </div>
      </div>{" "}
    </>,
    document?.getElementById("consent-root-modal")
  );
}

export default CookieConsent;
